import { Box } from "@mui/material";

import HeroContent from "../heroContent/HeroContent";

const HeroSection = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box display="flex" justifyContent="center" height="60vh" width="100%">
          <HeroContent />
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
