import frontEndPhoto from "../../assets/frontend-photo.png";
import backEndPhoto from "../../assets/backend-photo.png";
import mobilePhoto from "../../assets/mobile-photo.png";

import { ReactComponent as Lightbulb } from "../../assets/lightbulb.svg";
import { ReactComponent as Hand } from "../../assets/hand.svg";
import { ReactComponent as Ux } from "../../assets/ux.svg";
import { ReactComponent as Wheel } from "../../assets/wheel.svg";

import phoneIcon from "../../assets/phone.png";
import balanceIcon from "../../assets/balance.png";
import codeIcon from "../../assets/code.png";

import { Typography } from "@mui/material";

export const services = [
  {
    title: "Diseño",
    topTitle: "",
    content:
      "Visualizamos su negocio en el mundo digital y adaptamos su marca al mundo digital",
    topIcon: {
      icon: <Lightbulb width="45" height="45" />,
      size: { width: 45, height: 45 },
    },
  },
  {
    title: "UX",
    topTitle: "",
    content:
      "Haremos que sus necesidades se conviertan en funciones optimas para su aplicación",
    topIcon: {
      icon: <Ux width="45" height="45" />,
      size: { width: 45, height: 45 },
    },
  },
  {
    title: "Digital",
    topTitle: "Transformación",
    content:
      "Adaptamos su negocio y facilitamos herramientas para mejorar su productividad",
    topIcon: {
      icon: <Hand width="45" height="45" />,
      size: { width: 45, height: 45 },
    },
  },
  {
    title: "actualización",
    topTitle: "Soporte y",
    content:
      "Apoyamos el desarrollo de su empresa al dar soporte y mantenimiento de sus proyectos",
    topIcon: {
      icon: <Wheel width="45" height="45" />,
      size: { width: 45, height: 45 },
    },
  },
];

export const specialties = [
  {
    title: "FRONT END",
    content:
      "Desarrollamos tu sitio web tomando en cuenta tu idea inicial y creamos tomando en cuenta la experiencia de usuario e imagen de tu negocio. Ademas nos aseguramos de optimizar tu sitio web con la tecnología mas actual.",
    imgSrc: frontEndPhoto,
  },
  {
    title: "BACK END",
    content:
      "Proveemos soluciones en Backend para sitios web, que necesitan almacenamiento, administración y procesamiento de datos",
    imgSrc: backEndPhoto,
    contentPosition: "right",
  },
  {
    title: "MOBILE",
    content:
      "Creamos aplicaciones móviles modernas y funcionales para ampliar, y hacer crecer su negocio. En el mundo actual la tecnología móvil es indispensable para destacar su empresa o proyecto.",
    imgSrc: mobilePhoto,
  },
];

export const northtechData = [
  {
    title: "Visión",
    topTitle: "",
    topIcon: {
      icon: <img src={codeIcon} alt="" width="45" height="45" loading="lazy" />,
      size: { width: 45, height: 45 },
    },
    content: (
      <Typography>
        Ser una empresa líder en el ámbito tecnológico creando un lazo de
        confianza con nuestros clientes, satisfaciendo sus necesidades a la
        medida en tiempo y forma
      </Typography>
    ),
  },
  {
    title: "a clientes",
    topTitle: "Servicio orientado",
    topIcon: {
      icon: (
        <img src={balanceIcon} alt="" width="45" height="45" loading="lazy" />
      ),
      size: { width: 45, height: 45 },
    },
    content: (
      <Typography>
        <Typography
          sx={(theme) => ({
            textShadow: `1px 0 0 ${theme.palette.primary.main}`,
            display: "inline",
          })}
        >
          NorthTec
        </Typography>{" "}
        es una empresa dedicada a ofrecer el mejor servicio a nuestros clientes,
        conociendo sus necesidades y plasmándolas en algo real.
      </Typography>
    ),
  },
  {
    title: "a su negocio",
    topTitle: "Nos adaptamos",
    topIcon: {
      icon: (
        <img src={phoneIcon} alt="" width="45" height="45" loading="lazy" />
      ),
      size: { width: 45, height: 45 },
    },
    content: (
      <Typography>
        En{" "}
        <Typography
          sx={(theme) => ({
            textShadow: `1px 0 0 ${theme.palette.primary.main}`,
            display: "inline",
          })}
        >
          NorthTec
        </Typography>{" "}
        conocemos la tecnología y podemos desarrollar lo que sea necesario para
        que su negocio siempre se exponga de la mejor manera.
      </Typography>
    ),
  },
];
