import { useEffect, useState } from "react";

import { Box, Container, Stack, Typography } from "@mui/material";

import NavigationMenu from "../navigationMenu/NavigationMenu";
import CustomButton from "../button/CustomButton";

import { FaWhatsapp } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

import northtecIcon from "../../assets/northtec-icon.png";
import { Link } from "react-scroll";
import { phoneNumber, phoneNumberLong } from "../../utils/data";

const Header = ({ sectionRef, theme }) => {
  const [headerPosition, setHeaderPosition] = useState("static");

  useEffect(() => {
    const headerOffsetTop = sectionRef.current?.offsetTop;

    const handleScroll = () => {
      if (window.scrollY <= headerOffsetTop) {
        setHeaderPosition("static");
      } else {
        setHeaderPosition("fixed");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sectionRef]);
  const handleWhatsAppClick = () => {
    window.open(
      `https://wa.me/${phoneNumber}`,
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <header style={{ backgroundColor: "transparent" }} id="main">
      <Container
        maxWidth="lg"
        sx={{
          position: "relative",
          pt: "15px",
          display: { xs: "none", md: "block" },
        }}
      >
        <Stack
          spacing={5}
          direction={{ xs: "column", md: "row" }}
          color={theme.palette.primary.light}
          display="flex"
          mb="15px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            alignItems="center"
            spacing={4}
          >
            <Box
              component="img"
              src={northtecIcon}
              sx={{ width: "40px", height: "40px" }}
            ></Box>
            <Typography>
              Te damos la tecnología para que tu empresa pueda crecer
            </Typography>
            <CustomButton size="small">
              <Link to="designSolutions" smooth>
                Saber más
              </Link>
            </CustomButton>
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
            <Typography variant="body2" noWrap onClick={handleWhatsAppClick}>
              <Stack direction="row" alignItems="center">
                <FaWhatsapp />
                <span style={{ paddingLeft: "7px" }}>
                  {`${phoneNumberLong}`}
                </span>
              </Stack>
            </Typography>
            <Typography variant="body2" noWrap>
              <Stack direction="row" alignItems="center">
                <FaLocationDot />{" "}
                <span style={{ paddingLeft: "7px" }}>San José, Costa Rica</span>
              </Stack>
            </Typography>
          </Stack>
        </Stack>
      </Container>

      <Box sx={{ py: "10px" }}>
        <Box
          sx={{
            inset: 0,
            padding: "10px 20px 0 20px",
            zIndex: 30,
            maxHeight: "65px",
            position: headerPosition,
            background: "transparent",
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: "40px",
              border:
                headerPosition === "static"
                  ? "none"
                  : `2px solid ${theme.palette.primary.main}50`,
            }}
          >
            <NavigationMenu />
          </Container>
        </Box>
      </Box>
    </header>
  );
};

export default Header;
