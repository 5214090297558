import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/storage";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBxfbGJp0YAyBfQ10Qx7bW3ljmlWxMTrSY",
  authDomain: "northtec-c1d07.firebaseapp.com",
  projectId: "northtec-c1d07",
  storageBucket: "northtec-c1d07.appspot.com",
  messagingSenderId: "541675835882",
  appId: "1:541675835882:web:efecedbc3f945de3daa293",
  measurementId: "G-JBLR1SX1WN",
};

firebase.initializeApp(firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = firebase.firestore();

export { db };
