import { Box, Grid, Typography } from "@mui/material";

import InfoCard from "../infoCard/InfoCard";

const ServiceSection = ({ services, sectionRef }) => {
  return (
    <Box component="section" py={15} id="services" ref={sectionRef}>
      <Grid container rowSpacing={5} columnGap={3} justifyContent="center">
        {services.map((service) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              display="flex"
              justifyContent="center"
              mb={4}
            >
              <InfoCard
                title={service.title}
                topTitle={service.topTitle}
                topIcon={service.topIcon}
              >
                <Typography variant="body1">{service.content}</Typography>
              </InfoCard>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ServiceSection;
