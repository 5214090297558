import { lazy, Suspense, useState } from "react";

import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

import CustomButton from "../button/CustomButton";

import northtecLogo from "../../assets/northtec-logo.png";

// import AppointmentModal from "../forms/ScheduleAppointment/AppointmentModal";
import { Link } from "react-scroll";

const AppointmentModal = lazy(() =>
  import("../forms/ScheduleAppointment/AppointmentModal")
);

const HeroContent = () => {
  const theme = useTheme();

  const [openModal, setOpenModal] = useState(false);

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      color={theme.palette.primary.light}
    >
      <Box textAlign="center" margin="40px auto" padding="0 20px">
        <Box
          src={northtecLogo}
          alt="Logo"
          width="250px"
          height="auto"
          component="img"
          mb={4}
        />
        <Typography variant="h3" component="h1" fontWeight="bold">
          Convertimos sus necesidades
        </Typography>
        <Typography variant="h3" component="h1" fontWeight="bold">
          en funciones óptimas de su negocio
        </Typography>

        <Stack mt={3} direction="row" justifyContent="center" spacing={4}>
          <CustomButton onClick={() => setOpenModal(true)}>
            AGENDAR CITA
          </CustomButton>
          <CustomButton sx={{ display: { xs: "block", md: "none" } }}>
            <Link smooth to="designSolutions">
              SABER MAS
            </Link>
          </CustomButton>
        </Stack>
      </Box>

      <Suspense>
        <AppointmentModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
        />
      </Suspense>
    </Box>
  );
};

export default HeroContent;
