import { useEffect, useRef, useState, lazy, Suspense } from "react";

import { Box, Container } from "@mui/material";
import { useTheme } from "@emotion/react";

import heroCover from "../../assets/hero-cover.webp";
import heroCoverMobile from "../../assets/hero-cover-mobile.png";
import { services, specialties, northtechData } from "./homeData";

import { SiReact, SiMicrosoftazure } from "react-icons/si";
import { FaAngular, FaAws, FaWordpress } from "react-icons/fa";
import { SiFlutter, SiNextdotjs } from "react-icons/si";
import { SiGooglecloud, SiDrupal } from "react-icons/si";
import { FaNode } from "react-icons/fa6";

import { getAllProjects } from "../../firebase/projects/projects";

import Header from "../../components/header/Header";
import HeroSection from "../../components/heroSection/HeroSection";
import ServiceSection from "../../components/serviceSection/ServiceSection";

import Spinner from "../../components/spinner/Spinner";

const SpecialtiesSection = lazy(() =>
  import("../../components/specialtiesSection/SpecialtiesSection")
);
const TechnologiesSection = lazy(() =>
  import("../../components/technologiesSection/TechnologiesSection")
);
const ProjectsSection = lazy(() =>
  import("../../components/projectsSection/ProjectsSection")
);
const AboutUsSection = lazy(() =>
  import("../../components/aboutUsSection/AboutUsSection")
);
const ContactSection = lazy(() =>
  import("../../components/contactSection/ContactSection")
);
const DesignSolutinosSection = lazy(() =>
  import("../../components/designSolutionsSection/DesignSolutionsSection")
);
const VisualIdentitySection = lazy(() =>
  import("../../components/visualIdentitySection/VisualIdentitySection")
);
const Footer = lazy(() => import("../../components/footer/Footer"));

const technologyIconList = [
  { name: "React", icon: <SiReact size={65} /> },
  { name: "React Native", icon: <SiReact size={65} /> },
  { name: "Angular", icon: <FaAngular size={65} /> },
  { name: "Flutter", icon: <SiFlutter size={65} /> },
  { name: "NextJs", icon: <SiNextdotjs size={65} /> },
  { name: "AWS", icon: <FaAws size={65} /> },
  { name: "Azure", icon: <SiMicrosoftazure size={65} /> },
  { name: "Google Cloud", icon: <SiGooglecloud size={65} /> },
  { name: "NodeJs", icon: <FaNode size={65} /> },
  { name: "Drupal", icon: <SiDrupal size={65} /> },
  { name: "WordPress", icon: <FaWordpress size={65} /> },
];

const Home = () => {
  const sectionRef = useRef(null);
  const [projectList, setProjectList] = useState([]);

  const theme = useTheme();

  useEffect(() => {
    const getProjects = async () => {
      const projects = await getAllProjects();

      setProjectList(projects);
    };

    getProjects();
  }, []);

  return (
    <>
      <Box pb={15}>
        <Box
          sx={{
            backgroundImage: {
              xs: `url(${heroCoverMobile})`,
              md: `url(${heroCover}) `,
            },
            height: "75vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Header sectionRef={sectionRef} theme={theme} />
          <HeroSection />
        </Box>
      </Box>

      <ServiceSection services={services} sectionRef={sectionRef} />

      <Suspense fallback={<Spinner />}>
        <Container>
          <SpecialtiesSection specialties={specialties} theme={theme} />

          <DesignSolutinosSection theme={theme} />

          <VisualIdentitySection />

          <TechnologiesSection technologyIconList={technologyIconList} />
        </Container>

        <ProjectsSection projectList={projectList} theme={theme} />

        <Box position="relative">
          <AboutUsSection data={northtechData} theme={theme} />
        </Box>

        <ContactSection theme={theme} />

        <Footer theme={theme} />
      </Suspense>
    </>
  );
};

export default Home;
