import { Button } from "@mui/material";

import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: "18px",
  color: theme.palette.primary.dark,
  fontWeight: 900,
  textTransform: "none",
  minWidth: "140px",
  lineHeight: 1.7,

  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.dark,
  },
}));

const CustomButton = ({ children, ...otherProps }) => {
  return <StyledButton {...otherProps}>{children}</StyledButton>;
};

export default CustomButton;
