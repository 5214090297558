import { db } from "../firebase-config";

export const getAllProjects = async () => {
  try {
    const projects = [];
    const result = await db
      .collection("projects")
      .where("isActive", "==", true)
      .get();

    result.docs.forEach((doc) => {
      projects.push({ id: doc.id, ...doc.data() });
    });

    projects.sort(
      (firstValue, secondValue) =>
        firstValue.orderNumber - secondValue.orderNumber
    );

    return projects;
  } catch (error) {
    console.log(error);
  }
};

export const createAppointment = async (data) => {
  try {
    const innerCollection = db
      .collection("contacts")
      .doc("gQTLFjlwMStfY3JvGFko")
      .collection("appointmentRequests");
    const newDoc = await innerCollection.add(data);

    return newDoc.id;
  } catch (error) {
    console.log(error);
  }
};

export const createMessage = async (data) => {
  try {
    const innerCollection = db
      .collection("contacts")
      .doc("gQTLFjlwMStfY3JvGFko")
      .collection("messages");
    const newDoc = await innerCollection.add(data);

    return newDoc.id;
  } catch (error) {
    console.log(error);
  }
};
