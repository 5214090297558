import { Box, Typography, styled } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";

const StyledCard = styled(Box)(({ theme }) => ({
  position: "relative",
  boxShadow: "none",
  borderRadius: "18px 0 18px 0",
  backgroundColor: "transparent",
  border: `2px solid ${theme.palette.primary.main}50`,
  maxWidth: "250px",
  minWidth: "250px",
  minHeight: "150px",
  // maxHeight: "150px",
  color: theme.palette.primary.main,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  position: "absolute",
  backgroundColor: theme.palette.primary.dark,
  padding: "0 10px",
  display: "block",
  top: -20,
  left: 15,
  color: theme.palette.secondary.main,
  fontWeight: 900,
  fontSize: "24px",
  zIndex: 10,
}));

const StyledTopTitle = styled(Typography)(({ theme }) => ({
  position: "absolute",
  padding: "0 10px",
  display: "block",
  top: -45,
  left: 15,
  color: theme.palette.secondary.main,
  fontWeight: 900,
  fontSize: "24px",
  zIndex: 20,
  width: "100%",
}));

const StyledTopIcon = styled(Icon)(({ theme }) => ({
  position: "absolute",
  top: -60,
  right: 0,
}));

const InfoCard = ({ children, title, topTitle, topIcon, ...otherProps }) => {
  const {
    contentColor,
    cardBorderColor,
    titleColor,
    cardMaxWidth,
    cardBorderRadius,
  } = otherProps;

  return (
    <StyledCard
      sx={{
        borderColor: cardBorderColor,
        color: contentColor,
        maxWidth: cardMaxWidth,
        borderRadius: cardBorderRadius,
      }}
    >
      <StyledTopTitle sx={{ color: titleColor }}>{topTitle}</StyledTopTitle>
      <StyledTitle sx={{ color: titleColor }}>{title}</StyledTitle>
      <StyledTopIcon
        sx={{ width: topIcon?.size?.width, height: topIcon?.size?.height }}
      >
        {topIcon?.icon}
      </StyledTopIcon>
      <CardContent
        sx={{ pt: 3, justifyContent: "center", alignItems: "center" }}
      >
        {children}
      </CardContent>
    </StyledCard>
  );
};

export default InfoCard;
