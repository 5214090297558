import { useRef, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";

import { HiOutlineMenu } from "react-icons/hi";

import northtecLogo from "../../assets/northtec-logo.png";
import northtecIcon from "../../assets/northtec-icon.png";

import { Link } from "react-scroll";

const sectionList = [
  { name: "Inicio", idRef: "main", href: "#main" },
  { name: "Proyectos", idRef: "projects", href: "#projects" },
  { name: "Nosotros", idRef: "aboutUs", href: "#aboutUs" },
  { name: "Contacto", idRef: "contact", href: "#contact" },
];

const MenuSlide = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "-2px",
  left: 0,
  height: "4px",
  backgroundColor: theme.palette.primary.main,
  transition: "all 0.7s",
}));

const NavigationMenu = () => {
  const theme = useTheme();
  const matches600 = useMediaQuery("(max-width:600px)");

  const [anchorElNav, setAnchorElNav] = useState(false);
  const menuSlide = useRef(null);
  const firstButtonRef = useRef(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{ boxShadow: "none", background: "transparent" }}
    >
      <Toolbar>
        <Box display={{ xs: "block", md: "none" }}>
          <Link to={sectionList[0].idRef} smooth href={sectionList[0].href}>
            {matches600 ? (
              <img
                src={northtecIcon}
                alt="Brand logo"
                width="40px"
                height="40px"
              />
            ) : (
              <img
                src={northtecLogo}
                alt="Brand logo"
                width="140px"
                height="auto"
              />
            )}
          </Link>
        </Box>

        {/* Responsive menu starts here */}
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "none" },
            alignItems: "center",
          }}
        >
          <IconButton
            size="large"
            aria-label="navigation appbar"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            sx={{ marginLeft: "auto", padding: 0 }}
          >
            <HiOutlineMenu color={theme.palette.primary.dark} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {sectionList.map((section) => (
              <MenuItem key={section.name}>
                <Typography textAlign="center">
                  <Link
                    to={section.idRef}
                    smooth
                    style={{
                      color: theme.palette.primary.dark,
                      textDecoration: "none",
                    }}
                    href={section.href}
                    onClick={() => handleCloseNavMenu()}
                  >
                    {section.name}
                  </Link>
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {/* No responsive menu starts here */}
        <Box
          sx={{
            width: { xs: "100%", lg: "100%" },
            display: { xs: "none", md: "flex" },
            justifyContent: { xs: "space-between", lg: "space-between" },
            position: "relative",
          }}
        >
          {sectionList.map((section, index) => {
            return (
              <Button
                key={section.name}
                ref={index === 0 ? firstButtonRef : null}
                sx={{
                  color: theme.palette.primary.dark,
                  display: "block",
                  fontSize: "20px",
                  paddingRight: 0,
                  paddingLeft: 0,
                  textTransform: "capitalize",
                }}
              >
                <Link
                  to={section.idRef}
                  smooth
                  offset={-90}
                  style={{
                    color: theme.palette.primary.dark,
                    textDecoration: "none",
                  }}
                  href={section.href}
                >
                  {section.name}
                </Link>
              </Button>
            );
          })}

          <MenuSlide ref={menuSlide} />
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          {/* <Tooltip title="Open settings"></Tooltip> */}
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            //anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            //open={Boolean(anchorElUser)}
            //onClose={handleCloseUserMenu}
          ></Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationMenu;
