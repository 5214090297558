import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import northtecTheme from "./styles/northtecTheme";

import AppRouter from "./router/AppRouter";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={northtecTheme}>
        <GlobalStyles
          styles={{
            html: {
              overflowX: "hidden",
            },
            body: {
              backgroundColor: "#15151d",
              margin: 0,
              overflowX: "hidden",
            },
            section: { padding: "0 20px" },
          }}
        />
        <AppRouter />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
