import { Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Spinner = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100dvh"
    >
      <CircularProgress />
    </Stack>
  );
};

export default Spinner;
