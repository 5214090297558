import { createTheme, responsiveFontSizes } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00cccc",
      dark: "#15151d",
      light: "#f2f2f2",
      contrastText: "white",
    },
    secondary: {
      main: "#f7931e",
    },
    terciary: "#006f75",
  },
  typography: {
    h1: {
      fontFamily: "Montserrat",
    },
    h2: {
      fontFamily: "Montserrat",
    },
    h3: {
      fontFamily: "Montserrat",
    },
    h4: {
      fontFamily: "Montserrat",
    },
    h5: {
      fontFamily: "Montserrat",
    },
    body1: {
      fontFamily: "Nexa",
    },
    body2: {
      fontFamily: "Nexa",
    },
    button: {
      fontFamily: "Nexa",
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "red",
        },
      },
    },
  },
});

const northtecTheme = responsiveFontSizes(theme);

export default northtecTheme;
